<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="70%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>How To Claim</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="closeDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="12">
                            <p>
                                <ul>
                                    <li>
                                        Certified proof of identity for the claimant (certified copy 
                                        of ID or certified copy of birth certificate).
                                    </li>
                                    <li>
                                        Certified proof of identity for the deceased (certified copy 
                                        of ID or certified copy of birth certificate).
                                    </li>
                                    <li>
                                        Certified copy of death certificate of the deceased.
                                    </li>
                                    <li>
                                        Fully completed police report, if the cause of death is 
                                        unnatural; accidental; or suicide.
                                    </li>
                                    <li>
                                        Certified copy of BI 1663 or DHA 1663 or BI 1680.
                                    </li>
                                    <li>
                                        Completed documents must be sent to claims@afrosurance.co.za
                                    </li>
                                </ul>
                            </p>
                        </v-col>
                    </v-row>
                </v-card-text>
                <!-- Button Section -->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        large
                        color="amber"
                        @click="closeDialog()"
                    >
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>

import { eventBus } from "../main";

export default {
    components: {
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        }
    },
    data: () => ({
        
    }),
    methods: {
        closeDialog() {
            eventBus.$emit('CLOSE_HOW_TO_CLAIM_DIALOG');
        },
    },
    mounted() {
    },
    watch: {
        
    }
}
</script>
<style scoped>
.error-multi-line {
  white-space: pre-line;
}

</style>