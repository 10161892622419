<template>
    <div>
        <v-container class="info-section" style="text-align: left !important">
            <h1>Personal Cover</h1>

            <p>
                At Afrosurance Brokers, we understand the differing funeral needs for our clients. 
                As such, we have designed our products based on the differing affordability levels 
                of our clients to offer a decent final send off to their clients. Our benefit payouts 
                go up to R20 000 with a premium from as little as R85 per month. Below is what our 
                product offers:
            </p>
            <p>
                <ul>
                    <li>Cover for the Principal Member and up to 9 dependents</li>
                    <li>Basic funeral benefit for both natural and accidental deaths</li>
                    <li>The benefit will be paid out in cash</li>
                    <li>Cover continues for as long as premiums are paid</li>
                </ul>
            </p>
        </v-container>
        <br>

        <v-container class="info-section">
            <h1>Personal Cover Packages</h1>

            <v-row>
                <v-col sm="12" md="3">
                    <PersonalCoverComponent title="Bronze Package" ranking="bronze"/>
                </v-col>
                <v-col sm="12" md="3">
                    <PersonalCoverComponent title="Silver Package" ranking="silver"/>
                </v-col>
                <v-col sm="12" md="3">
                    <PersonalCoverComponent title="Gold Package" ranking="gold"/>
                </v-col>
                <v-col sm="12" md="3">
                    <PersonalCoverComponent title="Platinum Package" ranking="platinum"/>
                </v-col>
            </v-row>
        </v-container>
        <br>
    </div>
</template>

<script>
import PersonalCoverComponent from "../components/PersonalCoverComponent.vue";
export default {
    name: 'PersonalCoverPage',
    components: {
        PersonalCoverComponent,
    },

    data: () => ({
    }),
    methods: {
    },
    mounted() {
        
    }
}
</script>
<style scoped>
.info-section-override {
    background: #4791a0c2;
    color: #ffffff;
    text-align: center;
    padding: 30px !important;
}
</style>