<template>
    <v-card
        class="mx-auto my-12"
    >
        <template slot="progress">
            <v-progress-linear
                color="deep-purple"
                height="10"
                indeterminate
            ></v-progress-linear>
        </template>

        <v-card-title class="text-center">{{title}}</v-card-title>
        <v-divider class="mx-4"></v-divider>

        <v-card-text>
            <h4 v-if="type === 'members_only'">Cover of up to <h2>R40k</h2> for main members</h4>
            <h4 v-if="type === 'members_and_direct_family'">Cover of up to <h2>R30k</h2> for main members. Payout for dependents will be on the member certificate</h4>
            <h4 v-if="type === 'members_and_up_to_9_dependents'">Cover of up to <h2>R20k</h2> for main members. Payout for dependents will be on the member certificate</h4>
        </v-card-text>

        <v-divider class="mx-4"></v-divider>

        <v-card-actions>
            <v-btn
                class="signup-btn"
                block
                text
                @click="openSignupDialog()"
            >
                Signup
            </v-btn>
        </v-card-actions>
        <br>
        
        <SignupGroupCover :dialog="open_signup_for_group_cover_dialog" :selected_cover="title"/>
    </v-card>
</template>
<script>
import SignupGroupCover from "../dialogs/SignupGroupCover.vue";
import { eventBus } from "../main";
export default {
    props: {
        title: {
            type: String,
            required: true,
            default: ''
        },
        type: {
            type: String,
            required: true,
            default: 'members_only'
        },
    },
    components: {
        SignupGroupCover
    },
    data: () => ({
        selection: 1,
        open_signup_for_group_cover_dialog: false
    }),

    methods: {
        signupPackage() {
        },

        openSignupDialog() {
            this.open_signup_for_group_cover_dialog = true;
        },
        closeSignupDialog() {
            this.open_signup_for_group_cover_dialog = false;
        },
    },

    mounted() {
        eventBus.$on('CLOSE_GROUP_COVER_SIGNUP_DIALOG', this.closeSignupDialog);
    }
}
</script>
<style scoped>
.signup-btn {
    background: #f8c412 !important;
    color: #000000 !important;
}

</style>