<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="70%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Signup For {{selected_cover}}</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="closeDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="12">
                            <div>Capture your details below. And a representative will be in touch with you to discuss further details</div>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-text-field
                                v-model="prospect.main.name"
                                ref="name_field"
                                color="secondary"
                                label="Name"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-text-field
                                v-model="prospect.main.surname"
                                ref="surname_field"
                                color="secondary"
                                label="Surname"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-text-field
                                v-model="prospect.main.phone"
                                ref="phone_field"
                                color="secondary"
                                label="Phone"
                                type="number"
                                required
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row v-if="submitting">
                        <v-col cols="12" sm="11" md="12">
                            <h3>Submitting your details ...</h3>
                            <v-progress-linear
                                color="green"
                                class="mb-0"
                                height="5"
                                indeterminate
                                :active="submitting"
                            ></v-progress-linear>
                        </v-col>
                    </v-row>
                </v-card-text>
                <!-- Button Section -->
                <v-card-actions>
                    <v-btn
                        large
                        style="color: white"
                        color="#ff5200"
                        @click="closeDialog()"
                    >
                        Cancel
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        large
                        color="success"
                        @click="submitToServer()"
                    >
                        Signup
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>

import { eventBus } from "../main";
import { emitAlert, emitSuccess, submitToBackend } from '../utils/api';

export default {
    components: {
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        selected_cover: {
            type: String,
            required: true,
            default: ''
        }
    },
    data: () => ({
        submitting: false,
        prospect: {
            main: {
                name: '',
                surname: '',
                phone: '',
            },
        }
    }),
    methods: {
        closeDialog() {
            eventBus.$emit('CLOSE_GROUP_COVER_SIGNUP_DIALOG');
        },
        addDependent() {
            const size = this.prospect.dependents.length;

            this.prospect.dependents.push({
                id: size + 1,
                name: '',
                surname: '',
                marital_status: 'Single',
                id_number: ''
            });
        },

        removeDependent() {
            this.prospect.dependents.pop();
        },

        resetForm() {
            this.submitting = false;
            this.prospect = {
                main: {
                    name: '',
                    surname: '',
                    phone: ''
                },
            };
        },

        async submitToServer() {
            if (
                this.prospect.main.name.trim().length === 0 ||
                this.prospect.main.surname.trim().length === 0 ||
                this.prospect.main.phone.trim().length === 0
            ) {
                emitAlert('Please capture all the details required');
                return;
            }

            this.submitting = true;
            const payloadObj = {
                type: 'PROSPECT_REG',
                prospect: {
                    product: this.selected_cover,
                    ...this.prospect.main
                }
            };

            try {
                // First create the business_partner
                const res = await submitToBackend(payloadObj);

                emitSuccess(res.message);
                this.resetForm();
                this.closeDialog();
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }

                return;
            }
        }
    },
    mounted() {
    },
    watch: {
        
    }
}
</script>
<style scoped>
.error-multi-line {
  white-space: pre-line;
}

</style>