<template>
    <v-app>
        <v-snackbar
            :value="hideAlert"
            :color="alert.color"
            :timeout="alert.timeout"
            @input="resetSnackBarValue"
            multi-line
            top
        >
            <v-layout align-center pr-4>
                <v-icon class="pr-3" dark large>{{ alert.icon }}</v-icon>
                <v-layout column>
                    <div>
                        <strong>{{ alert.title }}</strong>
                    </div>
                    <div>{{ alert.message }}</div>
                </v-layout>
            </v-layout>

            <template v-slot:action="{ attrs }">
                <v-btn
                    color="white"
                    icon
                    dark
                    text
                    v-bind="attrs"
                    @click="resetSnackBarValue"
                >
                    <v-icon>clear</v-icon>
                </v-btn>
            </template>
        </v-snackbar>


        <v-app-bar
            app
            color="amber"
            fixed
        >
            <!-- ONLY SHOW THE FOLLOWING ON SMALL SCREENS (by using class="hidden-md-and-up") -->
            <v-app-bar-nav-icon
                color="#000000"
                class="hidden-md-and-up"
                @click="openDrawer()"
            ></v-app-bar-nav-icon>
            <span
                class="title ml-3 mr-5 hidden-md-and-up"
            >
                <span style="color:#000000">
                    Afrosurance&nbsp;
                </span>
            </span>
            <!-- <v-spacer></v-spacer> -->


            <!-- THE FOLLOWING NAV BUTTONS ARE THE DEFAULT VIEW -->
            <v-container class="mx-auto py-0">
                <!-- HIDE THIS DEFAULT VIEW FOR SMALL SCREENS BY USING class="hidden-sm-and-down" -->
                <v-bottom-navigation
                    fixed
                    v-model="nav_value"
                    class="hidden-sm-and-down"
                    background-color="transparent"
                >
                    <v-spacer></v-spacer>
                    <v-img
                        :src="require('@/assets/afrosurance-logo-no-text.png')"
                        alt="Afrosurance Logo"
                        class="mr-5"
                        contain
                        height="55"
                        width="55"
                        max-width="60"
                        link
                        @click="navigate('/')"
                    />

                    <v-btn
                        value="home"
                        @click="navigate('/')"
                    >
                        <span>Home</span>
                    </v-btn>
                    <v-spacer></v-spacer>

                    <v-btn
                        value="aboutus"
                        @click="navigate('/about-us')"
                    >
                        <span>About Us</span>
                    </v-btn>
                    <v-spacer></v-spacer>

                    <v-btn
                        value="personalcover"
                        @click="navigate('/personal-cover')"
                    >
                        <span>Personal Cover</span>
                    </v-btn>
                    <v-spacer></v-spacer>

                    <v-btn
                        value="groupcover"
                        @click="navigate('/group-cover')"
                    >
                        <span>Group Cover</span>
                    </v-btn>
                    <v-spacer></v-spacer>

                    <v-btn
                        value="termsandconditions"
                        @click="navigate('/terms-and-conditions')"
                    >
                        <span>Terms And Conditions</span>
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-bottom-navigation>
            </v-container>
        </v-app-bar>


        <!-------------------------- NAVIGATION DRAWER FOR SMALL SCREENS -------------------------->
        <v-navigation-drawer
            v-model="drawer"
            app
            clipped
            color="grey lighten-4"
        >
            <v-list-item link @click="navigate('/')">
                <v-list-item-content>
                    <v-list-item-title class="text-h6">
                        <v-img
                            src="@/assets/afrosurance-logo-white-bg.png"
                            alt="Afrosurance Logo"
                            width="70%"
                        />
                    </v-list-item-title>
                    <!-- <v-list-item-subtitle>
                        Afro
                    </v-list-item-subtitle> -->
                </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>

            <v-list
                dense
                class="grey lighten-4"
            >
                <template v-for="(item, i) in items">
                    <!-- Menu Item Heading -->
                    <v-row
                        v-if="item.heading"
                        :key="i + '-heading-' + item.heading"
                        align="center"
                    >
                        <v-col cols="12" style="padding-top:0px">
                            <v-subheader v-if="item.heading && item.display===true">
                                <b>{{ item.heading }}</b>
                            </v-subheader>
                        </v-col>
                    </v-row>
                    <!-- Menu item divider -->
                    <v-divider
                        v-if="item.divider === true && item.display === true"
                        :key="i  + '-divider'"
                        dark
                        class="my-4"
                    ></v-divider>
                    <!-- Menu item -->
                    <div v-else-if="item.display === true && item.path" :key="i + 'menu'">
                        <v-list-item
                            link
                            @click="navigate(item.path)"
                            :style="$route.path===item.path ? 'background: #f6c209' : ''"
                        >
                            <!-- <v-list-item-action>
                                <v-icon>{{ item.icon }}</v-icon>
                            </v-list-item-action> -->
                            <v-list-item-content>
                                <v-list-item-title>
                                {{ item.text }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </div>
                </template>
            </v-list>
        </v-navigation-drawer>

        <v-main :class="container_css">
            <v-container>
                <keep-alive>
                    <v-scale-transition mode="out-in">
                        <router-view :key="$route.fullPath"/>
                    </v-scale-transition>
                </keep-alive>
            </v-container>
        </v-main>

        <!-- <v-footer app> -->
        <v-footer padless class="afrosurance-footer">
            <v-container>
                <v-row>
                    <v-col
                        cols="12"
                        md="5"
                    >
                        <h3 class="mb-3">
                            Subscribe
                        </h3>

                        <p>
                            Enter your email address to subscribe to our blog
                            and receive notifications of new posts by email.
                        </p>

                        <!-- <v-row class="pa-2"> -->
                        <v-row no-gutters>
                            <!-- <v-responsive
                                class="overflow-visible mr-2"
                                max-width="400"
                            >
                                <v-text-field
                                    hide-details
                                    label="Your Email Address"
                                    solo
                                />
                            </v-responsive> -->
                            <v-col cols="12" sm="9">
                                <v-text-field
                                    v-model="mail_list_signup"
                                    hide-details
                                    label="Your Email Address"
                                    solo
                                />
                            </v-col>

                            <v-col cols="12" sm="3">
                                <v-btn
                                    class="ma-0"
                                    color="amber"
                                    style="height: 48px"
                                    @click="submitEmailToServer()"
                                >
                                    Subscribe
                                </v-btn>
                            </v-col>

                            <v-col v-if="submitting" cols="12" sm="11" md="12">
                                <h3>Submitting your details ...</h3>
                                <v-progress-linear
                                    color="green"
                                    class="mb-0"
                                    height="5"
                                    indeterminate
                                    :active="submitting"
                                ></v-progress-linear>
                            </v-col>
                        </v-row>
                    </v-col>

                    <v-col
                        cols="12"
                        md="6"
                        offset-md="1"
                    >
                        <h3>Contact</h3>

                        <v-list class="transparent">
                            <v-list-item>
                                <v-list-item-avatar color="amber">
                                    <v-icon
                                        class="elevation-4"
                                        color="primary"
                                    >
                                        mdi-email-outline
                                    </v-icon>
                                </v-list-item-avatar>

                                <v-list-item-title style="color:white">Email</v-list-item-title>

                                <v-list-item-subtitle style="color:white" class="text-wrap">
                                    info@afrosurance.com
                                </v-list-item-subtitle>
                            </v-list-item>

                            <v-list-item>
                                <v-list-item-avatar color="amber">
                                    <v-icon
                                        class="elevation-4"
                                        color="primary"
                                    >
                                        mdi-phone
                                    </v-icon>
                                </v-list-item-avatar>

                                <v-list-item-title style="color:white">Phone</v-list-item-title>

                                <v-list-item-subtitle style="color:white" class="text-wrap">
                                    +27 79 556 5218 / +27 71 804 4073
                                </v-list-item-subtitle>
                            </v-list-item>

                            <v-list-item>
                                <v-list-item-avatar color="amber">
                                    <v-icon
                                        class="elevation-4"
                                        color="primary"
                                    >
                                        mdi-map-marker
                                    </v-icon>
                                </v-list-item-avatar>

                                <v-list-item-title style="color:white">Address - Limpopo</v-list-item-title>

                                <v-list-item-subtitle style="color:white" class="text-wrap">
                                    Rapitsi village, Ga-Moshole, Office no.2
                                </v-list-item-subtitle>
                                <!-- <div>Rapitsi village, Ga-Moshole, Office no.2</div> -->
                            </v-list-item>

                            <v-list-item>
                                <v-list-item-avatar color="amber">
                                    <v-icon
                                        class="elevation-4"
                                        color="primary"
                                    >
                                        mdi-map-marker
                                    </v-icon>
                                </v-list-item-avatar>

                                <v-list-item-title style="color:white">Address - Gauteng</v-list-item-title>

                                <v-list-item-subtitle style="color:white" class="text-wrap">
                                    6206B Immink Dr, Diepkloof Zone 4, Soweto, 1864
                                </v-list-item-subtitle>
                            </v-list-item>
                        </v-list>
                    </v-col>
                </v-row>
            </v-container>
            
            <v-col class="text-center" cols="12" style="color:#ffffff; border-top: 1px solid #f8c412">
                Copyright &copy; <strong>afrosurance</strong> {{ new Date().getFullYear() }}
            </v-col>
        </v-footer>
    </v-app>
</template>

<script>
// import HelloWorld from './components/HelloWorld';
import { eventBus } from "./main";
import alert_mixin from "./mixins/alerts";
import { emitAlert, emitSuccess, submitToBackend } from './utils/api';

export default {
    name: 'App',

    mixins: [alert_mixin],

    components: {
        // HelloWorld,
    },

    data: () => ({
        //
        submitting: false,
        mail_list_signup: '',

        drawer: false,

        items: [
            { text: 'Home', path: '/', display: true },
            { text: 'About Us', path: '/about-us', display: true },
            { text: 'Personal Cover', path: '/personal-cover', display: true },
            { text: 'Group Cover', path: '/group-cover', display: true },
            { text: 'Terms And Conditions', path: '/terms-and-conditions', display: true },
        ],
    }),
    methods: {
        openDrawer() {
            this.drawer = !this.drawer;
        },
        navigate(path) {
            if (this.$route.path !== path) {
                this.$router.push(path);
            }
        },
        resetSnackBarValue() {
            // Reset snackbar alert so it can show again when another error is triggered
            eventBus.$emit('resetAlert');
        },

        async submitEmailToServer() {
            if (
                this.mail_list_signup.trim().length === 0
            ) {
                emitAlert('Please capture your email address');
                return;
            }

            this.submitting = true;
            const payloadObj = {
                type: 'MAIL_LIST_REG',
                mail_info: {
                    email: this.mail_list_signup,
                }
            };

            try {
                // First create the business_partner
                const res = await submitToBackend(payloadObj);

                emitSuccess(res.message);
                // this.resetForm();

                this.submitting = false;
                this.mail_list_signup = '';
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }

                return;
            }
        }
    },

    computed: {
        container_css() {
            switch (this.$route.path) {
                case '/':
                    return 'container-background-1';
                case '/about-us':
                    return 'container-background-2';
                case '/terms-and-conditions':
                    return 'container-background-3';
                case '/personal-cover':
                    return 'container-background-4';
                case '/group-cover':
                    return 'container-background-1';
                default:
                    return 'container-background-1'
            }
        },
        nav_value: {
            get: function() {
                switch (this.$route.path) {
                    case '/':
                        return 'home';
                    case '/about-us':
                        return 'aboutus';
                    case '/terms-and-conditions':
                        return 'termsandconditions';
                    case '/personal-cover':
                        return 'personalcover';
                    case '/group-cover':
                        return 'groupcover';
                    default:
                        return 'home'
                }
            },
            set: function() {},
        },
    }
};
</script>
<style>
    .container-background-1 {
        background:url('./assets/background1-min.jpeg') no-repeat center center fixed;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    }
    .container-background-2 {
        background:url('./assets/background2-min.jpeg') no-repeat center center fixed;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    }
    .container-background-3 {
        background:url('./assets/background3-min.jpeg') no-repeat center center fixed;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    }
    .container-background-4 {
        background:url('./assets/background4-min.jpeg') no-repeat center center fixed;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    }

    .top-menu {
        background: #f6c20fba;
    }

    .info-section {
        background: #4791a0c2;
        color: #ffffff;
        text-align: center;
        padding: 30px !important;
    }

    .afrosurance-footer {
        background: #2d2d2f !important;
        color: #ffffff !important;
        border: 2px solid #f8c412 !important;
    }


    .v-item-group.v-bottom-navigation .v-btn.v-btn--active {
        color: #ffffff !important;
        background: #4791a0 !important;
    }
</style>
