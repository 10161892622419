<template>
    <div>
        <v-container class="info-section" style="text-align: left !important">
            <h1>Group Cover</h1>

            <p>
                At Afrosurance Brokers, we understand the differing funeral needs for our clients.
                Our groups range from Companies, Churches, stokvels, burial societies and funeral 
                parlours who are particularly concerned about funeral benefits for their members 
                and their members’ families. 
            </p>
        </v-container>
        <br>

        <v-container class="info-section">
            <h1>Group Cover Options</h1>

            <v-row>
                <v-col cols="12" sm="4" xs="12" md="4" lg="4" xl="4">
                    <GroupCoverComponent title="Members Only" type="members_only"/>
                </v-col>
                <v-col cols="12" sm="4" xs="12" md="4" lg="4" xl="4">
                    <GroupCoverComponent title="Members And Direct Family" type="members_and_direct_family"/>
                </v-col>
                <v-col cols="12" sm="4" xs="12" md="4" lg="4" xl="4">
                    <GroupCoverComponent title="Members And Up To 9 Dependents" type="members_and_up_to_9_dependents"/>
                </v-col>
            </v-row>
        </v-container>
        <br>
    </div>
</template>

<script>
import GroupCoverComponent from "../components/GroupCoverComponent.vue";
export default {
    name: 'GroupCoverPage',
    components: {
        GroupCoverComponent
    }
}
</script>
<style scoped>
.info-section-override {
    background: #4791a0c2;
    color: #ffffff;
    text-align: center;
    padding: 30px !important;
}
</style>