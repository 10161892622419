import axios from 'axios';
import { eventBus } from '../main';

axios.defaults.baseURL = 'https://b27occvizc.execute-api.af-south-1.amazonaws.com/default/AfrosuranceWebsiteHandler';
axios.defaults.timeout = 60000;

// error overides
const axiosOverride = axios.create();

// error alert messages
function emitAlert(alert_message) {
    if (!alert_message) {
        eventBus.$emit('alertError', 'Server connection error, please try again later. If the problem persists, please contact the administrator.');
    } else {
        eventBus.$emit('alertError', `Error: ${alert_message}`);
    }
    // eventBus.$emit('progressBar', false);
}

function emitSuccess(success_message) {
    eventBus.$emit('alertSuccess', `Success: ${success_message}`);
}

function clearAlert() {
    eventBus.$emit('alertClear');
}

// request interceptor
axiosOverride.interceptors.request.use((config) => {
    config.headers['Access-Control-Allow-Origin'] = `*`;

    // config.onUploadProgress(progress)
    return config;
}, (error) => {
    emitAlert();
    return Promise.reject(error);
});

// response interceptor
axiosOverride.interceptors.response.use((response) => {
    clearAlert();
    return response;
}, (error) => {
    console.log('error', error)

    emitAlert(error.response.data);
    return Promise.reject(error);
});



//************************************************* api calls ************************************************//

function submitToBackend(payloadObj) {
    const url = ``;
    // return axiosOverride.post(url, payloadObj).then(res => res.data);
    return axiosOverride(
        {
            method: 'POST',
            url: url,
            headers: {
                'Access-Control-Allow-Origin': '*',
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            // withCredentials: true,
            // credentials: 'same-origin',
            // crossdomain: true,
            mode: 'no-cors',
            data: payloadObj,
        }
    ).then(res => res.data);
}

export {
    // General Error
    emitAlert,
    emitSuccess,

    // Backend submit
    submitToBackend
}
