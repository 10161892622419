<template>
    <div
        id="about"
        tag="section"
    >
        <v-container
            class="info-section"
        >
            <div>
                <v-row align="center">
                    <v-img
                        :src="require('@/assets/afrosurance-logo-white-bg.png')"
                        alt="Afrosurance Logo"
                        class="mr-5"
                        contain
                        height="250"
                        width="250"
                    />
                </v-row>
            </div>
            <br>

            <h1>Afrosurance Brokers</h1>

            <p>
                Afrosurance is a fintech that is integrating finance and technology with the African
                rural and informal economy
            </p>
            <p>
                We provide financial products in a way that is appropriate, simple and
                dignified for people in the rural and informal sector, considering their
                tech savviness and level of financial education. <br>
                Our strategy involves increasing access and the sustainable use of a
                diverse range of financial products and services that are appropriate to
                customer needs and economic circumstances.
            </p>
        </v-container>

        <br>

        <v-container
            class="info-section"
        >
            <v-row align="center">
                <v-col sm="6">
                    <v-btn class="action-btns" @click="openHowToClaimDialog()">
                        How To Claim
                    </v-btn>
                </v-col>
                <v-col sm="6">
                    <v-btn class="action-btns" @click="openThingsToNoteDialog()">
                        Things To Note
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
        <br>

        <ThingsToNote :dialog="open_things_to_note_dialog"/>
        <HowToClaim :dialog="open_how_to_claim_dialog"/>
    </div>
</template>

<script>
import ThingsToNote from "../dialogs/ThingsToNote.vue";
import HowToClaim from "../dialogs/HowToClaim.vue";
import { eventBus } from "../main";

export default {
    name: 'Home',
    components: {
        ThingsToNote,
        HowToClaim
    },
    data: () => ({
        open_things_to_note_dialog: false,
        open_how_to_claim_dialog: false
    }),
    methods: {
        openThingsToNoteDialog() {
            this.open_things_to_note_dialog = true;
        },
        closeThingsToNoteDialog() {
            this.open_things_to_note_dialog = false;
        },

        openHowToClaimDialog() {
            this.open_how_to_claim_dialog = true;
        },
        closeHowToClaimDialog() {
            this.open_how_to_claim_dialog = false;
        },
    },
    mounted() {
        eventBus.$on('CLOSE_THINGS_TO_NOTE_DIALOG', this.closeThingsToNoteDialog);
        eventBus.$on('CLOSE_HOW_TO_CLAIM_DIALOG', this.closeHowToClaimDialog);
    }
}
</script>
<style scoped>
.action-btns {
    background: #f8c412 !important;
    color: #000000 !important;
}

</style>
