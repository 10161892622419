import Vue from 'vue';
import VueRouter from 'vue-router';

import Home from './pages/Home.vue';
import AboutUs from './pages/AboutUs.vue';
import PersonalCover from './pages/PersonalCover.vue';
import GroupCover from './pages/GroupCover.vue';
import TermsAndConditions from './pages/TermsAndConditions.vue';


Vue.use(VueRouter);

export default new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/',
            component: Home,
            meta: { keepAlive: true }
        },
        {
            path: '/about-us',
            component: AboutUs,
            meta: { keepAlive: true }
        },
        {
            path: '/personal-cover',
            component: PersonalCover,
            meta: { keepAlive: true }
        },
        {
            path: '/group-cover',
            component: GroupCover,
            meta: { keepAlive: true }
        },
        {
            path: '/terms-and-conditions',
            component: TermsAndConditions,
            meta: { keepAlive: true }
        },
    ],
  });
  