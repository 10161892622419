<template>
    <v-container
        id="about"
        tag="section"
        class="info-section"
    >
        <h1>Terms and Conditions: Afrosurance Brokers (Pty) Ltd</h1><br>

        <p>
            <b>Afrosurance Funeral Cover</b> (or this "cover” or “plan") is subject to the terms, conditions and exclusions set out in this document.
        </p>

        <v-row>
            <v-col sm="12">
                <p>
                    <ol>
                        <li>
                            <b>Who is the underwriter of this cover?</b><br>
                            <p>
                                <b>Safrican</b> is the underwriter of this cover. Any reference to 
                                "we, us, our, ourselves" in this document shall mean Afrosurance or 
                                Safrican as the case may be.
                            </p>
                        </li>
                        <li>
                            <b>Who is insured under the plan?</b><br>
                            <p>
                                The persons insured and the amount for which they are insured under 
                                the Plan are specified in the Policy Certificate issued by Afrosurance. 
                                All the persons to be insured under the policy must be related to the 
                                Principal Member and the Principal Member must have an insurable 
                                interest in all insured persons under the policy. All insured lives 
                                must be South African citizens or permanent residents and be in 
                                possession of a green bar-coded RSA ID book or a Smart ID Card. 
                                If the insured life is younger than 18 years, a valid birth certificate 
                                is acceptable.
                            </p>
                            <p>Insured persons may only include:</p>
                            <p>
                                <b>Principal Member: </b> The main insured life (or main member) on the 
                                plan who owns this plan and is responsible for the payment of the premiums. 
                                For the purposes of this document, the main member will be referred to as 
                                “you, your, yourself”. You can only be insured as a main member on one 
                                Plan offered by Afrosurance. Children below the age of 18 years are not 
                                permitted to be Principal Members on the policy.
                            </p>
                            <p>
                                <b>Spouse: </b> The person who you are married to in terms of South 
                                African law, or your life partner with whom you live and have been 
                                residing with for at least 24 months, in a long-term, open and 
                                acknowledged relationship, capable of being registered under South African 
                                law. Cover is limited to one spouse who is named in the Policy Certificate. 
                                If you are married in terms of the Recognition of Customary Marriages Act 
                                to more than one spouse, only one of them can be covered as a Spouse,
                                the rest can be covered as “Extended Family” 
                            </p>
                            <p>
                                <b>Child(ren): </b> Your biological children, legally adopted 
                                children and stepchildren.
                            </p>
                            <p>
                                <b>Extended Family: </b> Your additional customary law spouses, 
                                partner, your children over the age of 21 years, parents, parents in-law, 
                                brothers, sisters, grandchildren or any other close blood relatives 
                                (such as uncle, aunt, cousin, niece, nephew). <br>

                                It is your responsibility to ensure that all the insured persons’ 
                                relationships to you are correctly specified and that their personal 
                                details are correct. Please be aware that we may verify any insured 
                                persons’ relationship to you and their personal details at any time. 
                                Incorrect personal details could be considered a material 
                                misrepresentation which will result in us disputing or rejecting 
                                claims on the plan or cancelling cover or the plan itself.
                            </p>
                        </li>
                        <li>
                            <b>What are the insured lives insured for?</b><br>
                            <p>
                                This Plan is a Life Policy and pays out the specified cash benefit, 
                                as selected by you and stated in the Policy Certificate, in the event of 
                                death of any of the insured persons.
                            </p>
                            <p>
                                Accidental Death means a death caused solely by injury sustained during an 
                                Accident, during the cover of this plan, which is independent of all other 
                                causes, within 90 days after the Accident. Accident means any injury 
                                caused solely and unexpectedly, directly and independently of all other 
                                causes, sustained by external, violent or physical means. For example, 
                                a car crash.
                            </p>
                        </li>
                        <li>
                            <b>When does the cover and plan start?</b><br>
                            <p>
                                This plan and cover start on the day the Insurer accepts your application. 
                                We will notify you once accepted. A policy certificate will be issued once 
                                the Insurer accepts your application.
                            </p>
                            <p>
                                Conditions will take effect from the cover Start Date. The cover of other 
                                insured persons starts on the date on which they are loaded by us on the 
                                plan.
                            </p>
                        </li>
                    </ol>
                </p>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
  export default {
    name: 'TermsAndConditions',
  }
</script>
<style scoped>
.info-section {
    text-align: left !important;
}
</style>
