<template>
    <div>
        <v-container class="info-section">
            <h1>About Us</h1>

            <p>
                Afrosurance is a company that was founded to fundamentally change how products 
                and services are offered in the lower LSM market by integrating clients in this 
                segment with our easy to use advanced technology. We have discovered that current 
                financial products in the market are not answering the problem questions currently 
                existing in this segment and clients take up products and services with no other 
                convenient choice offered. Another discovery was how current technologies are difficult 
                to consume by this market due to their complexities. We believe the business shouldn’t 
                transfer technological complexities they can’t solve for to their clients.
            </p>
        </v-container>
        <br>

        <v-container class="info-section">
            <p>
                This has brought up a culture of client exploitation and clients feeling like businesses 
                don’t care. We saw this as an opportunity to build a business that cares. Our business 
                model revolves around caring for the clients which motivated us to build processes that can 
                effectively and efficiently trace customer journeys, raise flags on process delays and adapt 
                to new technological advances. As such, and because all founders grew up in lower LSM 
                households, we have taken the approach of going to these customers and asking about the 
                problem questions currently existing and we formulate products and services to solve for 
                those problems.
            </p>
        </v-container>
        <br>

        <v-container class="info-section">
            <p>
                As a business, we believe in uplifting communities we do business in. This will come in many 
                facets, ranging from the creation of products and services which creates value for our clients to 
                promoting the culture of entrepreneurship in these communities. We create entrepreneurship by 
                partnering with locals as independent contractors in driving business sales and by partnering 
                with local businesses on the benefits we can offer our clients. This will create an ecosystem 
                that drives money circulation within communities we do business in and thus help with the development 
                of such communities.
            </p>
        </v-container>
    </div>
</template>

<script>
  export default {
    name: 'AboutUs',
  }
</script>
