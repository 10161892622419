<template>
    <v-card
        class="mx-auto my-12"
    >
        <template slot="progress">
            <v-progress-linear
                color="deep-purple"
                height="10"
                indeterminate
            ></v-progress-linear>
        </template>

        <v-card-title class="text-center">{{title}}</v-card-title>
        <v-divider class="mx-4"></v-divider>

        <v-avatar height="200" width="200">
            <img
                v-if="ranking === 'bronze'"
                src="../assets/badges/bronze.png"
            >
            <img
                v-if="ranking === 'silver'"
                src="../assets/badges/silver.png"
            >
            <img
                v-if="ranking === 'gold'"
                src="../assets/badges/gold.png"
            >
            <img
                v-if="ranking === 'platinum'"
                src="../assets/badges/platinum.png"
            >
        </v-avatar>

        <v-divider class="mx-4"></v-divider>

        <v-card-text>
            <h3 v-if="ranking === 'bronze'">Up to R5K benefit payout</h3>
            <h3 v-if="ranking === 'silver'">Up to R10K benefit payout</h3>
            <h3 v-if="ranking === 'gold'">Up to R15K benefit payout</h3>
            <h3 v-if="ranking === 'platinum'">Up to R20K benefit payout</h3>
        </v-card-text>

        <v-divider class="mx-4"></v-divider>

        <v-card-actions>
            <v-btn
                class="signup-btn"
                block
                text
                @click="openSignupDialog()"
            >
                Signup
            </v-btn>
        </v-card-actions>
        <br>

        <SignupPersonalCoverDialog :dialog="open_signup_for_personal_cover_dialog" :selected_cover="title"/>
    </v-card>
</template>
<script>
import SignupPersonalCoverDialog from "../dialogs/SignupPersonalCover.vue";
import { eventBus } from "../main";
export default {
    props: {
        title: {
            type: String,
            required: true,
            default: ''
        },
        ranking: {
            type: String,
            required: true,
            default: 'bronze'
        },
    },
    components: {
        SignupPersonalCoverDialog
    },
    data: () => ({
        selection: 1,
        open_signup_for_personal_cover_dialog: false,
    }),

    methods: {
        signupPackage() {
        },

        openSignupDialog() {
            this.open_signup_for_personal_cover_dialog = true;
        },
        closeSignupDialog() {
            this.open_signup_for_personal_cover_dialog = false;
        },
    },

    mounted() {
        eventBus.$on('CLOSE_PERSONAL_COVER_SIGNUP_DIALOG', this.closeSignupDialog);
    }
}
</script>
<style scoped>
.signup-btn {
    background: #f8c412 !important;
    color: #000000 !important;
}

</style>