import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import appRouter from './router';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import '@mdi/font/css/materialdesignicons.css';

Vue.config.productionTip = false

Vue.use(VueAxios, axios);

export const eventBus = new Vue();

new Vue({
    router: appRouter,
    vuetify,
    render: h => h(App)
}).$mount('#app')
