<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="70%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Things To Note</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="closeDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="12">
                            <p>
                                <ul>
                                    <li>
                                        At Afrosurance, we understand the meaning of family. That’s why 
                                        we offer products that cover you and up to 9 family members for 
                                        events we dread the most.
                                    </li>
                                    <li>
                                        Get your funeral cover today and be financially prepared to 
                                        handle your family funeral expenses.
                                    </li>
                                    <li>
                                        The Maximum Entry age for Principal Members and Spouse is 64 
                                        years and all the dependants is 74 years.
                                    </li>
                                    <li>
                                        Clients over 74 years will be covered under a different policy 
                                        and priced at R80 per member with a 12 (twelve) month waiting period.
                                    </li>
                                    <li>
                                        There is a 6 (six) month waiting period applicable.
                                    </li>
                                    <li>
                                        24 Month waiting period for suicide from the date of inception will be applicable.
                                    </li>
                                    <li>
                                        If the premium due for the month is not paid within the month, the Plan will 
                                        lapse and the 6 (six) months.
                                    </li>
                                    <li>
                                        Waiting period will apply on any reinstatement
                                    </li>
                                    <li>
                                        Afrosurance is an authorised financial services provider. FSP number 51621
                                    </li>
                                    <li>
                                        Our products are underwritten by SAFRICAN INSURANCE COMPANY. Safrican is an 
                                        authorised financial services provider. FSP number 15123.
                                    </li>
                                </ul>
                            </p>
                        </v-col>
                    </v-row>
                </v-card-text>
                <!-- Button Section -->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        large
                        color="amber"
                        @click="closeDialog()"
                    >
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>

import { eventBus } from "../main";

export default {
    components: {
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        }
    },
    data: () => ({
        
    }),
    methods: {
        closeDialog() {
            eventBus.$emit('CLOSE_THINGS_TO_NOTE_DIALOG');
        },
    },
    mounted() {
    },
    watch: {
        
    }
}
</script>
<style scoped>
.error-multi-line {
  white-space: pre-line;
}

</style>